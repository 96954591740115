import React from "react";
import { RevenueChart } from "./RevenueChart";
import { MarketingChannelOverviewInterface } from "interface/MarketingChannelOverviewInterface";
import { EventSumInfo } from "@api/types/backendTypes";
import { useAtom } from "jotai";
import { dashboardModeAtom } from "atoms";

type Props = {
  dailyChannelData: { [date: string]: MarketingChannelOverviewInterface[] };
  dailyStoreEventsData?: { [date: string]: EventSumInfo };
  dailyComparedStoreEventsData?: { [date: string]: EventSumInfo };
  dailyComparedChannelData?: {
    [date: string]: MarketingChannelOverviewInterface[];
  };
  loading?: boolean;
  isInfluencerChart?: boolean;
};
/**
 * Holds the component representing the info for the revenue.
 *
 * @return {CustomElement}
 */
export function RevenueInfoContainer({
  dailyChannelData,
  loading,
  dailyStoreEventsData,
  dailyComparedChannelData,
  dailyComparedStoreEventsData,
  isInfluencerChart,
}: Props) {
  const [dashboardMode] = useAtom(dashboardModeAtom);

  if (loading) {
    return (
      <div className="relative bg-gray-100 dark:bg-gray-800 p-6 rounded-lg">
        <div className="animate-pulse">
          <div className="h-8 w-64 bg-gray-200 dark:bg-gray-700 rounded-md"></div>
          <div className="mt-8">
            <div className="h-[350px] w-full bg-gray-200 dark:bg-gray-700 rounded-xl"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="relative bg-gray-100 dark:bg-gray-800 p-6 rounded-lg">
      <h3 className="h3">
        {dashboardMode === "general" ? "Conv. Value" : "Revenue"} /{" "}
        {isInfluencerChart ? "Ad Spend" : "Channel Ad Spend"}
      </h3>
      <div className="mt-8">
        <RevenueChart
          dailyChannelData={dailyChannelData}
          dailyStoreEventsData={dailyStoreEventsData}
          dailyComparedChannelData={dailyComparedChannelData}
          dailyComparedStoreEventsData={dailyComparedStoreEventsData}
          title={`${
            dashboardMode === "general" ? "Conv. Value" : "Revenue"
          } / ${isInfluencerChart ? "Ad Spend" : "Channel Ad Spend"}`}
        />
      </div>
    </div>
  );
}

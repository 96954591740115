import React, { useEffect, useMemo, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { chartTooltipStyles, METRICS_LABELS } from "constants/constants";
import { useAtom } from "jotai";
import {
  comparedAtom,
  endTimeAtom,
  newVsReturningAtom,
  startTimeAtom,
} from "atoms";
import { getNearestAxisValue } from "@components/ChannelControlMetrics/ConversionValueChart";
import { kpiCalculations } from "@lib/util-functions";
import { MarketingChannelOverviewInterface } from "interface/MarketingChannelOverviewInterface";
import dayjs from "dayjs";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import useFormat from "@lib/hooks/use-format";
import { NewVsReturningType } from "@api/types/backendTypes";

type Props = {
  title: string;
  tooltip?: React.ReactNode;
  dailyChannelData: {
    new: { [date: string]: MarketingChannelOverviewInterface[] };
    returning: { [date: string]: MarketingChannelOverviewInterface[] };
    all: { [date: string]: MarketingChannelOverviewInterface[] };
    compared?: {
      new: { [date: string]: MarketingChannelOverviewInterface[] };
      returning: { [date: string]: MarketingChannelOverviewInterface[] };
      all: { [date: string]: MarketingChannelOverviewInterface[] };
    };
  };
  trackedOrdersRatio?: { [date: string]: number };
  dataKeys: (keyof typeof kpiCalculations)[];
  unit: string;
  colors: { [key: string]: string };
  chartRoundFactor?: number;
  loading: boolean;
};
const getChartData = ({
  dailyChannelData,
  newVsReturning,
  comparedTimerange,
}: {
  dailyChannelData: Props["dailyChannelData"];
  newVsReturning: NewVsReturningType;
  comparedTimerange?: number;
}) => {
  const currentChartData: { [key: string]: any }[] = [];
  let currentMaxValue = 0;
  for (const date of Object.keys(dailyChannelData.all)) {
    if (newVsReturning === "default") {
      const pageviews = dailyChannelData.all[date]?.reduce((prev, curr) => {
        return prev + curr.pageview;
      }, 0);
      if (pageviews > currentMaxValue) {
        currentMaxValue = pageviews;
      }

      currentChartData.push({
        date: comparedTimerange
          ? `${dayjs(date).add(comparedTimerange, "day").format("YYYY-MM-DD")}`
          : date,
        pageview: pageviews,
      });
    } else {
      const newPageviews =
        newVsReturning !== "returning"
          ? dailyChannelData.new[date]?.reduce((prev, curr) => {
              return prev + curr.pageview;
            }, 0)
          : 0;
      const returningPageviews =
        newVsReturning !== "new"
          ? dailyChannelData.returning[date]?.reduce((prev, curr) => {
              return prev + curr.pageview;
            }, 0)
          : 0;
      if (newPageviews + returningPageviews > currentMaxValue) {
        currentMaxValue = newPageviews + returningPageviews;
      }

      currentChartData.push({
        date: comparedTimerange
          ? `${dayjs(date).add(comparedTimerange, "day").format("YYYY-MM-DD")}`
          : date,
        new_pageview: newVsReturning !== "returning" ? newPageviews : undefined,
        returning_pageview:
          newVsReturning !== "new" ? returningPageviews : undefined,
      });
    }
  }
  return { currentChartData, currentMaxValue };
};
/**
 * Component representing the charts in store performance sector.
 *
 * @param {StorePerformancePropsInterface} props
 * @return {CustomElements}
 */
export function StoreSessionsChart({
  title,
  dailyChannelData,
  loading,
  unit,
}: Props) {
  const [chartData, setChartData] = useState<{ [key: string]: any }[]>([]);
  const [newVsReturning] = useAtom(newVsReturningAtom);
  const { selectedOrganisationData } = useSelectedStores();

  const { formatNumber } = useFormat();
  const [maxValue, setMaxValue] = useState(0);

  const compared = Boolean(
    Object.keys(dailyChannelData.compared?.all ?? {}).length > 0
  );
  const minDateFromComparedDaily = useMemo(
    () =>
      Math.min(
        ...Object.keys(dailyChannelData.compared?.all ?? {}).map((el) =>
          dayjs(el).unix()
        )
      ),
    [dailyChannelData.compared?.all]
  );
  const minDateFromDaily = useMemo(
    () =>
      Math.min(
        ...Object.keys(dailyChannelData.all).map((el) => dayjs(el).unix())
      ),
    [dailyChannelData.all]
  );
  const timerange = useMemo(
    () =>
      dayjs(minDateFromDaily * 1000).diff(
        dayjs(minDateFromComparedDaily * 1000),
        "day"
      ),
    [minDateFromDaily, minDateFromComparedDaily]
  );

  const maxYDomain = useMemo(() => getNearestAxisValue(maxValue), [maxValue]);

  useEffect(() => {
    setChartData([]);
    setMaxValue(0);

    if (
      dailyChannelData.new &&
      dailyChannelData.returning &&
      dailyChannelData.all
    ) {
      const calculatedChartData = getChartData({
        dailyChannelData,
        newVsReturning,
      });
      const { currentChartData, currentMaxValue } = calculatedChartData;
      let comparedChartData: typeof calculatedChartData | undefined;
      if (dailyChannelData.compared && timerange) {
        comparedChartData = getChartData({
          dailyChannelData: dailyChannelData.compared,
          newVsReturning,
          comparedTimerange: timerange,
        });
        for (const value of comparedChartData.currentChartData) {
          const indexInCurrent = currentChartData.findIndex(
            (el) => el.date === value.date
          );
          if (indexInCurrent !== -1) {
            if (newVsReturning === "default") {
              currentChartData[indexInCurrent][`compared_pageview`] =
                value["pageview"];
            } else {
              currentChartData[indexInCurrent][`compared_new_pageview`] =
                value["new_pageview"];
              currentChartData[indexInCurrent][`compared_returning_pageview`] =
                value["returning_pageview"];
            }
          }
        }
      }
      setChartData(currentChartData);
      setMaxValue(
        comparedChartData
          ? Math.max(comparedChartData.currentMaxValue, currentMaxValue)
          : currentMaxValue
      );
    }
  }, [dailyChannelData, newVsReturning, timerange]);

  const {
    allTotal,
    newTotal,
    returningTotal,
    comparedAllTotal,
    comparedNewTotal,
    comparedReturningTotal,
  } = useMemo(() => {
    let newTotal = 0;
    let returningTotal = 0;
    let allTotal = 0;

    let comparedNewTotal = 0;
    let comparedReturningTotal = 0;
    let comparedAllTotal = 0;

    for (const entry of chartData) {
      if (entry.new_pageview) newTotal += entry.new_pageview ?? 0;
      if (entry.returning_pageview)
        returningTotal += entry.returning_pageview ?? 0;
      if (entry.pageview) allTotal += entry.pageview ?? 0;
      if (entry.compared_new_pageview)
        comparedNewTotal += entry.compared_new_pageview ?? 0;
      if (entry.compared_returning_pageview)
        comparedReturningTotal += entry.compared_returning_pageview ?? 0;
      if (entry.compared_pageview)
        comparedAllTotal += entry.compared_pageview ?? 0;
    }
    if (newVsReturning !== "default") {
      allTotal = newTotal + returningTotal;
      comparedAllTotal = comparedNewTotal + comparedReturningTotal;
    }
    return {
      allTotal,
      newTotal,
      returningTotal,
      comparedAllTotal,
      comparedNewTotal,
      comparedReturningTotal,
    };
  }, [chartData, newVsReturning]);

  const [xAxisTicks, setXAxisTicks] = useState(
    dailyChannelData?.all
      ? Object.keys(dailyChannelData.all)
      : Object.keys(dailyChannelData)
  );
  useEffect(() => {
    function setXAxisTicksHandler() {
      const sessionChart = document.getElementById("session-chart-container");
      const width = sessionChart?.clientWidth ?? 0;
      const maxAmountOfTicks = Math.floor((width - 300) / (36 + 15)); // the labels are roughly 36px wide + a 15px spacing
      const dailyKeys = dailyChannelData?.all
        ? Object.keys(dailyChannelData.all)
        : Object.keys(dailyChannelData);
      const dateKeys = dailyKeys.length;
      const keysIndexToChoose = Math.ceil(dateKeys / maxAmountOfTicks);
      const keysToDisplay = dailyKeys
        .map((el, index) => (index % keysIndexToChoose === 0 ? el : null))
        .filter((el) => Boolean(el)) as string[];
      setXAxisTicks(keysToDisplay);
    }
    setXAxisTicksHandler();
    window.addEventListener("resize", setXAxisTicksHandler);
    return () => {
      window.removeEventListener("resize", setXAxisTicksHandler);
    };
  }, [dailyChannelData]);

  if (loading) {
    return (
      <div className="w-full h-full min-h-72 border-black p-6 flex flex-col rounded-lg bg-gray-100 dark:bg-gray-800">
        <div className="w-full h-full animate-pulse">
          <div className="h-7 w-48 rounded-md bg-gray-200 dark:bg-gray-700" />

          <div className="w-full h-60 animate-pulse bg-gray-200 dark:bg-gray-700 rounded-lg mt-4"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full min-h-72 border-black p-6 flex flex-col rounded-lg bg-gray-100 dark:bg-gray-800 ">
      <div className="flex justify-between items-baseline">
        <div className="">
          <h3 className="h3">{title}</h3>
          <p className="text-foreground-soft text-xs">
            {newVsReturning !== "default"
              ? "New vs returning visitors"
              : "All visitors"}
          </p>
        </div>
        <div className="flex space-x-6">
          {newVsReturning === "all" || newVsReturning === "default" ? (
            <div>
              <div className="flex items-center text-foreground-soft">
                <p className=" text-xs">Total</p>
              </div>
              <p className="font-bold text-lg">{formatNumber(allTotal, 0)}</p>
              {compared ? (
                <p className="text-xs text-foreground-soft">
                  vs {formatNumber(comparedAllTotal, 0)}
                </p>
              ) : null}
            </div>
          ) : null}
          {newVsReturning !== "default" ? (
            <>
              <div>
                <div className="flex items-center text-foreground-soft">
                  <div className="h-3 w-3 rounded-sm bg-greenRect mr-2"></div>
                  <p className=" text-xs">New</p>
                </div>
                <p className="font-bold text-lg">
                  {newTotal ? formatNumber(newTotal, 0) : "-"}
                </p>
                {compared ? (
                  <p className="text-xs text-foreground-soft">
                    vs{" "}
                    {comparedNewTotal ? formatNumber(comparedNewTotal, 0) : "-"}
                  </p>
                ) : null}
              </div>
              <div>
                <div className="flex items-center text-foreground-soft">
                  <div className="h-3 w-3 rounded-sm bg-blueRect mr-2"></div>
                  <p className=" text-xs">Returning </p>
                </div>
                <p className="font-bold text-lg">
                  {returningTotal ? formatNumber(returningTotal, 0) : "-"}
                </p>
                {compared ? (
                  <p className="text-xs text-foreground-soft">
                    vs{" "}
                    {comparedReturningTotal
                      ? formatNumber(comparedReturningTotal, 0)
                      : "-"}
                  </p>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="w-full h-72 bg-gray-100 dark:bg-gray-800">
        <ResponsiveContainer
          width="100%"
          height="100%"
          id="session-chart-container"
        >
          <AreaChart data={chartData} margin={{ top: 30, bottom: 5 }}>
            {compared ? null : (
              <defs>
                {newVsReturning === "default" ? (
                  <linearGradient id="pageview" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#27ae60" stopOpacity={0.9} />
                    <stop offset="95%" stopColor="#27ae60" stopOpacity={0.4} />
                  </linearGradient>
                ) : (
                  <>
                    <linearGradient
                      id="new_pageview"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#27ae60" stopOpacity={0.9} />
                      <stop
                        offset="95%"
                        stopColor="#27ae60"
                        stopOpacity={0.4}
                      />
                    </linearGradient>

                    <linearGradient
                      id="returning_pageview"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#4a3fcc" stopOpacity={0.9} />
                      <stop
                        offset="95%"
                        stopColor="#4a3fcc"
                        stopOpacity={0.4}
                      />
                    </linearGradient>
                  </>
                )}
              </defs>
            )}
            <XAxis
              dataKey="date"
              tickSize={0}
              tickMargin={20}
              axisLine={false}
              tickFormatter={(value: string) =>
                value
                  ? String(value)?.split("-").slice(1).reverse().join("/")
                  : ""
              }
              ticks={xAxisTicks}
            />
            <CartesianGrid stroke="var(--chart-grid)" vertical={false} />
            <YAxis
              tickFormatter={(value: number) => `${formatNumber(value, 0)}`}
              dataKey="new"
              unit={unit}
              tickSize={0}
              tickMargin={10}
              axisLine={false}
              tickCount={
                maxYDomain % 5 === 0
                  ? 6
                  : maxYDomain % 4 === 0
                    ? 5
                    : maxYDomain % 3 === 0
                      ? 4
                      : maxYDomain % 2 === 0
                        ? 3
                        : 2
              }
              domain={[0, maxYDomain]}
            />
            <Tooltip
              contentStyle={chartTooltipStyles}
              formatter={(value: string, name: string) => {
                let prefix = "";
                if (name.includes("compared_")) {
                  name = name.replace("compared_", "");
                  prefix = "Previous ";
                }

                return [
                  formatNumber(parseFloat(value), 0),
                  prefix +
                    (name === "pageview"
                      ? "All visitors"
                      : METRICS_LABELS[name]),
                ];
              }}
              itemSorter={(item: any) => {
                if (item.dataKey === "pageview") return 1;
                if (item.dataKey === "compared_pageview") return 2;
                if (item.dataKey === "new_pageview") return 3;
                if (item.dataKey === "compared_new_pageview") return 4;
                if (item.dataKey === "returning_pageview") return 5;
                if (item.dataKey === "compared_returning_pageview") return 6;
                return -1;
              }}
            />
            {newVsReturning === "default" ? (
              <>
                <Area
                  type="monotone"
                  dataKey="pageview"
                  stackId="1"
                  stroke="#27ae60"
                  dot={false}
                  strokeWidth={2}
                  fillOpacity={1}
                  fill={`url(#cr)`}
                />
                <Area
                  type="monotone"
                  dataKey="compared_pageview"
                  stackId="2"
                  stroke="#27ae60"
                  dot={false}
                  strokeWidth={2}
                  fillOpacity={0}
                  strokeDasharray={"5 5"}
                />
              </>
            ) : (
              <>
                {compared ? (
                  <Area
                    type="monotone"
                    dataKey="compared_returning_pageview"
                    stackId="2"
                    stroke="#4a3fcc"
                    dot={false}
                    strokeWidth={2}
                    strokeDasharray={"5 5"}
                    fillOpacity={0}
                  />
                ) : null}
                {compared ? (
                  <Area
                    type="monotone"
                    dataKey="compared_new_pageview"
                    stackId="2"
                    stroke="#27ae60"
                    dot={false}
                    strokeWidth={2}
                    strokeDasharray={"5 5"}
                    fillOpacity={0}
                  />
                ) : null}
                <Area
                  type="monotone"
                  dataKey="returning_pageview"
                  stackId="1"
                  stroke="#4a3fcc"
                  dot={false}
                  strokeWidth={2}
                  fillOpacity={1}
                  fill={`url(#aov)`}
                />
                <Area
                  type="monotone"
                  dataKey="new_pageview"
                  stackId="1"
                  stroke="#27ae60"
                  dot={false}
                  strokeWidth={2}
                  fillOpacity={1}
                  fill={`url(#cr)`}
                />
              </>
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

import React from "react";
import { MarketingChannelOverviewInterface } from "interface/MarketingChannelOverviewInterface";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { StorePerformanceChart } from "./StorePerformanceChart";
import { CardContainer } from "@components/Cards/CardContainer";
import { StoreSessionsChart } from "./StoreSessionsChart";
import useCurrency from "../../../lib/hooks/use-currency";
import { useAtom } from "jotai";
import { dashboardModeAtom } from "atoms";

type Props = {
  dailyChannelData: {
    new: { [key: string]: MarketingChannelOverviewInterface[] };
    returning: { [key: string]: MarketingChannelOverviewInterface[] };
    all: { [key: string]: MarketingChannelOverviewInterface[] };
    compared?: {
      new: { [key: string]: MarketingChannelOverviewInterface[] };
      returning: { [key: string]: MarketingChannelOverviewInterface[] };
      all: { [key: string]: MarketingChannelOverviewInterface[] };
    };
  };
  loading: boolean;
  trackedOrdersRatio?: { [date: string]: number };
};

/**
 * Component holding the store performance charts.
 *
 * @return {CustomElements}
 */
export function StorePerformanceContainer({
  dailyChannelData,
  trackedOrdersRatio,
  loading,
}: Props) {
  const { calculateCurrencyConvertion, globalCurrencySymbol } = useCurrency();
  const [dashboardMode] = useAtom(dashboardModeAtom);

  return (
    <CardContainer>
      <h3 className="h3">
        {dashboardMode === "general" ? "Site" : "Store"} Performance
      </h3>
      <div className="mt-4">
        <StoreSessionsChart
          title="Visitors"
          unit=""
          dailyChannelData={dailyChannelData}
          dataKeys={["aov"]}
          colors={{
            new_aov: "#3EA142",
            returning_aov: "#4A3FCC",
          }}
          loading={loading}
        />
      </div>
      <div className="w-full grid grid-cols-2 gap-6 items-stretch mt-6">
        <div className="h-full col-span-2 lg:col-span-1">
          <StorePerformanceChart
            title={
              dashboardMode === "general"
                ? "Average Conversion Value"
                : "Average Order Value"
            }
            unit={globalCurrencySymbol}
            dailyChannelData={dailyChannelData}
            dataKeys={["aov"]}
            colors={{
              new_aov: "#3EA142",
              returning_aov: "#4A3FCC",
              aov: "#4A3FCC",
            }}
            roundTotalValues={2}
            loading={loading}
            helperFormatFn={calculateCurrencyConvertion}
          />
        </div>
        <div className="h-full col-span-2 lg:col-span-1">
          <StorePerformanceChart
            title="Conversion Rate"
            tooltip={
              <Tooltip>
                <TooltipTrigger>
                  <InformationCircleIcon className="text-primary h-5 w-5" />
                </TooltipTrigger>
                <TooltipContent>
                  <div className="w-88 text-sm text-foreground-soft">
                    <p className="text-base text-foreground font-medium">
                      Conversion Rate
                    </p>
                    <p>
                      The conversion rate you see here and the conversion rate
                      your {dashboardMode === "general" ? "site" : "store"}{" "}
                      system provides may be different, as a session in Tracify
                      is only measured from the first visit to the purchase,
                      while your{" "}
                      {dashboardMode === "general" ? "site" : "store"} system
                      will most likely hold the session as long as the tracking
                      cookie is valid.
                    </p>
                  </div>
                </TooltipContent>
              </Tooltip>
            }
            unit="%"
            trackedOrdersRatio={trackedOrdersRatio}
            dailyChannelData={dailyChannelData}
            dataKeys={["cr"]}
            roundTotalValues={2}
            colors={{
              new_cr: "#3EA142",
              returning_cr: "#4A3FCC",
              cr: "#3EA142",
            }}
            loading={loading}
          />
        </div>
      </div>
    </CardContainer>
  );
}
